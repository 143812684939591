import type { FetchCompanyAttributes, WerkgelukUser } from "~/types";
import type { Strapi4ResponseData } from "@nuxtjs/strapi";

export const useWerkgelukUser = () => {
  const { find } = useStrapi();
  const { getCollectionConfig } = useCollectionConfig();
  const config = getCollectionConfig("company");
  const user = useStrapiUser<WerkgelukUser>();
  const refresh = async () => {
    const { fetchUser } = useStrapiAuth();
    await fetchUser();
  };
  const refreshingCompanies = useState("refreshingCompanies", () => false);
  const selectedCompanyId = useCookie<number>("selectedCompanyId");
  const availableCompanies = useState(
    `availableCompanies`,
    () => [] as Strapi4ResponseData<FetchCompanyAttributes>[],
  );

  const role = computed(() => user.value?.role);

  const refreshCompanies = async () => {
    const user = useStrapiUser<WerkgelukUser>();

    if (refreshingCompanies.value) return;
    if (!user.value) return;
    if (!user.value.registrationComplete) return;

    let filters: Record<string, unknown> = {};
    filters.id = {
      $in: user.value?.companies.map((company) => company.id),
    };

    if (user.value?.role && user.value?.role.type === "admin") {
      filters = {};
    }

    log(
      `Refreshing companies for user ${user.value?.id} with filters ${JSON.stringify(filters)}`,
    );

    refreshingCompanies.value = true;
    const data = await find<FetchCompanyAttributes>("companies", {
      filters,
      populate: config.populate,
      sort: "title:asc",
      pagination: {
        page: 1,
        pageSize: 9999,
      },
    });
    log(
      `Found ${data.data.length} companies for user ${user.value?.id}`,
      data.data,
    );
    availableCompanies.value = data.data;

    refreshingCompanies.value = false;
  };
  if (!availableCompanies.value.length) {
    refreshCompanies();
  }
  if (!selectedCompanyId.value && user.value?.companies.length > 0) {
    selectedCompanyId.value = user.value?.companies[0]?.id ?? 0;
  }
  if (user.value?.companies.length === 1) {
    selectedCompanyId.value = user.value?.companies[0]?.id ?? 0;
  }

  const selectedCompany = computed({
    get() {
      const c = availableCompanies.value?.find(
        (company) => company.id === selectedCompanyId.value,
      );
      return c;
    },
    set(value) {
      if (!value) {
        return;
      }
      selectedCompanyId.value = value.id;
    },
  });

  const availableCredits = computed(() => {
    return selectedCompany.value?.attributes.credits ?? 0;
  });

  //TODO: Add const to get the expire date of the credits

  watch(selectedCompanyId, async () => {
    const { refresh } = useMediaLibrary();
    await refresh();
  });

  return {
    refreshCompanies,
    refresh,
    user,
    availableCompanies,
    selectedCompany,
    availableCredits,
    role,
  };
};
